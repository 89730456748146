import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import FairwayHuntersIcon from '../../images/logo_no_background.png'
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavIcon,
  NavMenu,
  NavBtn,
  NavBtnLink,
  NavBtnExternalLink
} from './NavbarElements';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ toggle, darkText=false }) => {
  const history = useNavigate()
  const [scrollnav, setScrollnav] = useState(false);

  const noScrollTextColor = darkText ? "#000" : "#fff"

  const changeNav = () => {
    if (window.scrollY >= 40) {
      setScrollnav(true);
    } else {
      setScrollnav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const goHome = () => {
    history("/")
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav style = {{ 
            background: scrollnav ? 'rgb(50, 50, 50, 0.95)' : 'rgb(50, 50, 50, 0.1)', boxShadow: scrollnav ? "1px 1px 10px 1px rgb(40,40,40)": "none" }} >
          <NavbarContainer>
            {/* <NavLogo onClick={toggleHome} to='/'>
              tumble
            </NavLogo> */}
            <NavIcon src={FairwayHuntersIcon} onClick={goHome} />
            <MobileIcon onClick={toggle}>
              <FaBars color={scrollnav ? "#fff" : noScrollTextColor} />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to='/'
                  smooth='true'
                  duration={500}
                  spy='true'
                  exact='true'
                  offset={-80}
                  style={{ color: scrollnav ? '#fff' : noScrollTextColor }}
                >
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='/shop'
                  smooth='true'
                  duration={500}
                  spy='true'
                  exact='true'
                  offset={-80}
                  style={{ color: scrollnav ? '#fff' : noScrollTextColor }}
                >
                  Shop
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='/'
                  smooth='true'
                  duration={500}
                  spy='true'
                  exact='true'
                  offset={-80}
                  style={{ color: scrollnav ? '#fff' : noScrollTextColor }}
                >
                  Our Story
                </NavLinks>
              </NavItem>
            </NavMenu>
            {/* <NavBtn>
              <NavBtnExternalLink href='https://app.tumbledelivery.com/'>Login</NavBtnExternalLink>
            </NavBtn> */}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;