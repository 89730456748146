import React, { useState } from 'react';
import Navbar from '../../components/Navbar/index.js';
import Sidebar from '../../components/Sidebar/index.js';
import Footer from '../../components/Footer/index.js';
import ShopSection from '../../components/ShopSection/index.js';
import { Helmet } from "react-helmet";

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
        <Helmet>
          <title>Fairway Hunters Shop</title>
          <meta
            name="description"
            content="See the products Fairway Hunters has to offer."
          />
          <meta
            name="keywords"
            content="Golf, Hunting, Fairway, Hunters, Faith, God, Jesus, Christ, Redding, California, Apparel, Hats"
          />
        </Helmet>
        <Sidebar isopen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <ShopSection />
        <Footer />
    </>
  );
}

export default Home;