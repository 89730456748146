import React, { useEffect } from 'react';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages'
import Shop from './pages/shop'
import ItemDetail from './pages/shop/item_detail'
import Cart from './pages/cart'
import { auth } from './firebase';

function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/shop' element={<Shop />} />
        <Route path='/shop/detail/:id' element={<ItemDetail />} />
        <Route path='/shop/detail/:id' element={<ItemDetail />} />
      </Routes>
    </Router>
  );
}

export default App;
