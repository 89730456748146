import React, { useState, useEffect, Image } from 'react';
import { db } from '../../firebase';
import { collection, query, where, getDocs, setDoc, doc, addDoc } from "firebase/firestore";
import { useParams } from 'react-router-dom';
import { useWindowSize } from '../../utils';
import { WINDOW_WIDTH_CHANGE_TO_MOBILE_ITEM_DETAIL } from '../../constants/constants';
import { PRODUCT_IMAGES_URL_PREFIX } from '../../constants/constants';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';


function ShopDetailSection() {
  
    const { id } = useParams();

    const [windowWidth, windowHeight] = useWindowSize();

    const [itemData, setItemData] = useState({})

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")

    const [pageLoading, setPageLoading] = useState(true)
    const [dialogLoading, setDialogLoading] = useState(false)
    const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(false)
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
        setDialogLoading(false)
        setDisplayConfirmationDialog(false)
    };

    const submitPreOrder = async () => {
        setDialogLoading(true)
        const today = new Date();
        const month = today.getMonth()+1;
        const year = today.getFullYear();
        const date = today. getDate();
        const currentDate = month + "/" + date + "/" + year;
        await addDoc(collection(db, "preorders"), {
            email: email,
            itemId: itemData['itemId'],
            itemName: itemData['name'],
            name: name,
            phoneNumber: phoneNumber,
            date: currentDate
        })
        setDisplayConfirmationDialog(true)
        setDialogLoading(false)
    }

    const fetchData = async () => {
        const q = query(collection(db, "products"), where("itemId", "==", id));
        const querySnapshot = await getDocs(q);
        let itemDataLocal = {}
        querySnapshot.forEach((doc) => {
            const docData = doc.data()
            const imgUrls = []
            for (var i = 0; i < docData.images.length; i++) {
                const imgUrl = PRODUCT_IMAGES_URL_PREFIX + docData.images[i]
                imgUrls.push(imgUrl)
            }
            itemDataLocal = {
                name: docData.name,
                itemId: docData.itemId,
                images: imgUrls,
                color: docData.color,
                price: docData.price,
                quantityRemaining: docData.quantityRemaining,
                description: docData.description
            }
            setSelectedImage(imgUrls[0])
        });
        setItemData(itemDataLocal)
        setPageLoading(false)
    }
    
    useEffect(() => {
        fetchData()
    }, []);

    const selectImage = (event) => {
        const imageUrl = event.target.alt
        setSelectedImage(imageUrl)
    }

    const displayImages = () => {
        const imagesLocal = itemData['images']
        const imageIconList = []
        for (var i = 0; i < imagesLocal.length; i++) {
            const imageUrl = imagesLocal[i]
            if (imagesLocal[i] === selectedImage) {
                imageIconList.push(
                    <img src={`/${imageUrl}`} key={imageUrl} alt={imageUrl} style={{ width: "50px", marginRight: "20px", boxShadow: "1px 1px 10px 1px rgb(90,90,90)", cursor: "pointer", borderStyle: "solid", borderWidth: "2px" }} />
                )
            }
            else  {
                imageIconList.push(
                    <img src={`/${imageUrl}`} onClick={(event) => selectImage(event)} key={imageUrl} alt={imageUrl} style={{ width: "50px", marginRight: "20px", boxShadow: "1px 1px 10px 1px rgb(90,90,90)", cursor: "pointer" }} />
                )
            }
        }
        return (
            <>
                <img src={`/${selectedImage}`} alt="img" style={{ width: "100%", marginBottom: "10px" }} />
                {imageIconList}
            </>
        )
    }

    const displayItemData = () => {
        const itemId = itemData['itemId']
        const itemName = itemData['name']
        const itemDescription = itemData['description']
        const itemPrice = "$" + (itemData['price'] / 100).toString()
        if (windowWidth > WINDOW_WIDTH_CHANGE_TO_MOBILE_ITEM_DETAIL) {
            return (
                <div style={{ alignContent: "center" }}>
                    <h4 style={{ color: "#000" }}><a href='/' style={{ textDecoration: "none", color: "#000" }} >Home</a> / <a href='/shop' style={{ textDecoration: "none", color: "#000" }} >Shop</a> / <a href={`/shop/detail/${itemId}`} style={{ textDecoration: "none", color: "#000" }}>{itemName}</a></h4>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "50%", padding: "15px" }}>
                            {displayImages()}
                        </div>
                        <div style={{ width: "50%", padding: "15px" }}>
                            <p style={{ fontSize: "45px", paddingTop: "0px" }}>{itemName}</p>
                            <p style={{ fontSize: "30px", paddingTop: "7px" }}>{itemPrice}</p>
                            <p style={{ fontSize: "20px", paddingTop: "20px" }}>{itemDescription}</p>
                            <p style={{ fontSize: "15px", paddingTop: "20px" }}>Expected May 2024</p>
                            <Button style={{ marginTop: "20px", marginBottom: "20px" }} variant="outlined" onClick={handleClickOpen}>
                                Pre-Order
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div style={{ alignContent: "center" }}>
                    <h4 style={{ color: "#000" }}><a href='/' style={{ textDecoration: "none", color: "#000" }} >Home</a> / <a href='/shop' style={{ textDecoration: "none", color: "#000" }} >Shop</a> / <a href={`/shop/detail/${itemId}`} style={{ textDecoration: "none", color: "#000" }}>{itemName}</a></h4>
                    <div style={{ }}>
                        <div style={{ width: "100%", padding: "15px", display: "flex", justifyContent: "center" }}>
                            <div>
                                {displayImages()}
                            </div>
                        </div>
                        <div style={{ width: "100%", padding: "15px" }}>
                            <p style={{ fontSize: "45px", paddingTop: "0px" }}>{itemName}</p>
                            <p style={{ fontSize: "30px", paddingTop: "7px" }}>{itemPrice}</p>
                            <p style={{ fontSize: "20px", paddingTop: "20px" }}>{itemDescription}</p>
                            <p style={{ fontSize: "15px", paddingTop: "20px" }}>Expected May 2024</p>
                            <Button style={{ marginTop: "20px", marginBottom: "20px" }} variant="outlined" onClick={handleClickOpen}>
                                Pre-Order
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
        
    }

    if (pageLoading) {
        return (
            <>
            </>
        )
    }

    const displayDialog = () => {
        if (!open) {
            return (
                <></>
            )
        }
        if (dialogLoading) {
            return (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {`Pre-Order ${itemData['name']}`}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Loading...
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            )
        }
        else {
            if (displayConfirmationDialog) {
                return (
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                        {`Pre-Order ${itemData['name']}`}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Thank you!  You have been added to the pre-order list.  Be on the lookout for a email from us in the near future!
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                )
            }
            else {
                return (
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                        {`Pre-Order ${itemData['name']}`}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Please enter your email below to be added to the pre-order list.  When this item is in stock, we will email you and you will be the first to know!
                            </DialogContentText>
                            <TextField style={{ marginTop: "10px" }} fullWidth value={email} onChange={(event) => {setEmail(event.target.value) }} id="outlined-basic" label="Email" variant="outlined" />
                            <TextField style={{ marginTop: "10px" }} fullWidth value={name} onChange={(event) => {setName(event.target.value) }}id="outlined-basic" label="Name" variant="outlined" />
                            <TextField style={{ marginTop: "10px" }} fullWidth value={phoneNumber} onChange={(event) => {setPhoneNumber(event.target.value) }}id="outlined-basic" label="Phone Number (optional)" variant="outlined" />
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={submitPreOrder} autoFocus>
                            Submit
                        </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        }
    }

    return (
        <>
            <div style={{ marginTop: "125px", minHeight: "calc(100vh - 200px)", display: "flex", justifyContent: "center" }}>
                <div style={{ width: "90%", maxWidth: "900px" }}>
                    {displayItemData()}
                </div>
            </div>
            {displayDialog()}
        </>
    );
}

export default ShopDetailSection;