import React, { useState } from 'react';
import { Button, ExternalButton } from '../ButtonElements';

import BackgroundImage from '../../images/background.jpg'
import {
  HeroContainer,
  HeroPicture,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './HeroElements';

function HeroSection() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id='home' style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${BackgroundImage})` }}>
      <HeroContent>
        <HeroH1>Where Golfing Meets Hunting</HeroH1>
        <HeroP>
          From Greens to Game Trails
        </HeroP>
        <HeroBtnWrapper>
          <Button
            to='/shop'
            smooth='true'
            duration={500}
            spy='true'
            exact='true'
            offset={-80}
            primary='true'
            dark='true'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            Shop {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;