import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import { Helmet } from "react-helmet";

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
        <Helmet>
          <title>Fairway Hunters</title>
          <meta
            name="description"
            content="Where Hunting meets Golf"
          />
          <meta
            name="keywords"
            content="Golf, Hunting, Fairway, Hunters, Faith, God, Jesus, Christ, Redding, California, Apparel, Hats"
          />
        </Helmet>
        <Sidebar isopen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <HeroSection />
        <Footer />
    </>
  );
}

export default Home;