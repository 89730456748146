import React, { useState, useEffect, Image } from 'react';
import { db } from '../../firebase';
import { collection, query, getDocs } from '@firebase/firestore';
import { useNavigate } from "react-router-dom";
import { PRODUCT_IMAGES_URL_PREFIX } from '../../constants/constants';
import BackgroundImage from '../../images/yale_golf_course.jpg'
import { useWindowSize } from '../../utils';

function ShopSection() {
  
  const navigate = useNavigate();

  const [windowWidth, windowHeight] = useWindowSize();

  const [itemList, setItemList] = useState([]);

  const fetchData = async () => {
    const localItemList = []
    const querySnapshot = await getDocs(collection(db, "products"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const docData = doc.data()
      const imgUrls = []
      for (var i = 0; i < docData.images.length; i++) {
        const imgUrl = PRODUCT_IMAGES_URL_PREFIX + docData.images[i]
        imgUrls.push(imgUrl)
      }
      localItemList.push({
        name: docData.name,
        itemId: docData.itemId,
        images: imgUrls,
        color: docData.color,
        price: docData.price,
        quantityRemaining: docData.quantityRemaining
      })
    });
    setItemList(localItemList)
  }

  useEffect(() => {
    fetchData()
  }, []);

  const openDetailPage = (itemId) => {
    console.log(itemId)
    navigate("/shop/detail/" + itemId)
  }

  // Since we are using flexbox to display the items, and we want that
  // flexbox centered in the page but left align for incomplete rows,
  // we need to have justifyContent: center and then add empty items
  // to have the appearance of left align.  This function calculates
  // how many extra empty items we need.
  const makeCorrectionForEmptySpots = (totalItems) => {

    let itemsPerRow = 3
    if (windowWidth >= 900) {
      itemsPerRow = 3
    }
    else if (windowWidth < 900 && windowWidth >= 600) {
      itemsPerRow = 2
    }
    else if (windowWidth < 600) {
      itemsPerRow = 1
    }

    return totalItems % itemsPerRow
  }

  const createShopList = () => {
    const localList = []
    for (var i = 0; i < itemList.length; i++) {
      const imageUrl = itemList[i]['images'][0]
      const itemId = itemList[i]['itemId']
      const itemName = itemList[i]['name']
      const itemPrice = "$" + (itemList[i]['price'] / 100).toString()
      localList.push(
        <div key={i} onClick={() => openDetailPage(itemId)} style={{ marginBottom: "70px", cursor: "pointer", textAlign: "center", marginLeft: "35px", marginRight: "35px" }}>
            <img src={`/${imageUrl}`} alt="img" style={{ width: "200px", boxShadow: "1px 1px 10px 1px rgb(40,40,40)" }} />
            <p style={{ paddingTop: "10px", color: "white" }}>{itemName}</p>
            <p style={{ paddingTop: "10px", color: "white" }}>{itemPrice}</p>
        </div>
      )
    }
    const numOfEmtpySpotsToAdd = makeCorrectionForEmptySpots(itemList.length)
    for (var i = 0; i < numOfEmtpySpotsToAdd; i++) {
      localList.push(
        <div key={i} style={{ marginBottom: "70px", marginLeft: "35px", marginRight: "35px" }}>
            <div style={{ width: "200px" }} />
            <p style={{ paddingTop: "10px", color: "white" }}></p>
            <p style={{ paddingTop: "10px", color: "white" }}></p>
        </div>
      )
    }
    return (
      <div style={{ marginTop: "20px", display: "flex", width: "calc(100%)", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
        {localList}
      </div>
    )
  }


  // TODO: for mobile, change the filter styling.

  return (
    <>
      <div style={{  backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.6) ), url(${BackgroundImage})` }}>
        <div style={{ paddingTop: "125px", minHeight: "calc(100vh - 200px)", display: "flex", justifyContent: "center" }}>
            <div style={{ width: "90%", maxWidth: "900px" }}>
            <div style={{  }}>
              <h3 style={{ color: "white" }}><a href='/' style={{ color: "white", textDecoration: "none" }} >Home</a> / <a href='/shop' style={{ color: "white", textDecoration: "none" }} >Shop</a></h3>
            </div>
            {createShopList()}
          </div>
        </div>
      </div>
    </>
  );
}

export default ShopSection;