// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";import { getFirestore } from "@firebase/firestore"
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCPtQjV-AhUAsFLzsSIgUtRW6JIZ5UdVok",
  authDomain: "fairway-hunters.firebaseapp.com",
  projectId: "fairway-hunters",
  storageBucket: "fairway-hunters.appspot.com",
  messagingSenderId: "324942565634",
  appId: "1:324942565634:web:8d55877bb21dd77dc7721b",
  measurementId: "G-F1CSZ8PZ62"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);